import { ClinicSearchResult } from '@/api/algolia';
import { AUTOCOMPLETE } from '@/costants/enum';
import { AggregatorSpeciality } from '@/types-aggregatore';
import { capitalizeWordsInString } from '@/utils/string';

export const makeSpecializationTerms = (searchResponse: ClinicSearchResult) => {
  if (!searchResponse?.hits?.length) return [];

  const set = new Set(searchResponse.hits.flatMap(hit => hit?.specializations).map(spec => spec?.macroSpeciality?.toLowerCase()));
  return [...set].map(item => ({ type: AUTOCOMPLETE.SPECIALIZATION, value: capitalizeWordsInString(item) }));
};

export const makeServiceTerms = (searchResponse: ClinicSearchResult) => {
  if (!searchResponse?.hits?.length) return [];

  const set = new Set(searchResponse?.hits.flatMap(hit => hit?.specializations).map(spec => spec?.label?.toLowerCase()));
  return [...set].map(item => ({ type: AUTOCOMPLETE.SPECIALITY, value: capitalizeWordsInString(item) }));
};

export const makeAmbulatoryTerms = (searchResponse: ClinicSearchResult) => {
  if (!searchResponse?.hits?.length) return [];

  const set = new Set(searchResponse?.hits.flatMap(hit => hit?.name?.toLowerCase()));
  return [...set].map(item => ({ type: AUTOCOMPLETE.AMBULATORY, value: capitalizeWordsInString(item) }));
};

export const filterAlgoliaSearchResponseSpecialiazationsBySearchText = (
  searchResponse?: null | ClinicSearchResult,
  searchText?: string,
): AggregatorSpeciality[] =>
  searchResponse
    ? searchResponse.hits
        .map(hit => hit.specializations)
        .filter(
          speciality =>
            !!speciality?.macroSpeciality &&
            !!speciality?.label &&
            [speciality.macroSpeciality, speciality.label].some(str => str?.toLowerCase() === searchText?.toLowerCase()),
        )
        .sort((s1, s2) => <number>(s1.label?.localeCompare(s2.label ?? '') && !!s1.price && !!s2.price && s1.price - s2.price))
        .map(algoliaSpeciality => {
          return {
            id: algoliaSpeciality.id,
            favorite: algoliaSpeciality.favorite,
            doctors: algoliaSpeciality.doctors,
            label: algoliaSpeciality.label,
            price: algoliaSpeciality.price,
            specialityChannels: algoliaSpeciality.specialityChannels,
            hasDynamicPrice: algoliaSpeciality.hasDynamicPrice,
          };
        })
    : [];
