import Container from '@/app/_components/container';
import { ELButton } from '@davincihealthcare/elty-design-system-react';
import Image from 'next/image';

type HeaderTantosvagoProps = {
  name: string;
  surname: string;
  logo: string;
  onGoBack: () => void;
};

const HeaderTantosvago = ({ logo, name, surname, onGoBack }: HeaderTantosvagoProps) => {
  return (
    <Container className="fixed z-[250] w-full border-b-2 border-secondary bg-white">
      <div className="flex justify-between">
        <div className="flex items-center text-sm md:text-base  md:font-bold">
          <div className="ml-4 flex items-center">
            <span className="hidden md:flex">Ciao&nbsp;</span>
            <strong className="hidden text-secondary md:flex">{`${name} ${surname}`}&nbsp;</strong>
          </div>
          <div className="text-xs md:text-base">stai prenotando tramite</div>
          <div className="relative ml-4 h-8 min-w-16 md:h-10 md:min-w-24">
            <Image src={logo} alt="logo-partner" fill objectFit="contain" />
          </div>
        </div>
        <div className="text-primary-900 relative m-5 flex items-center font-bold ">
          <ELButton
            size="small"
            color="primary"
            aria-label="Torna al portale"
            label="Torna al portale"
            onClick={onGoBack}
            variant="outlined"
          >
            Torna al portale
          </ELButton>
        </div>
      </div>
    </Container>
  );
};

export default HeaderTantosvago;
