import clsx from 'clsx';
import Image from 'next/image';
import Link from 'next/link';

type NavItemProps = {
  url: string;
  iconUrl: string;
  title: string;
};

export const NavItem = ({ url, title, iconUrl }: NavItemProps) => {
  return (
    <li role="menuitem">
      <Link href={url}>
        <button className={clsx('flex flex-row items-center justify-start gap-2 hover:text-black focus:text-black', 'text-[#6B7280]')}>
          {<Image className={'opacity-70'} alt="" src={iconUrl} width={20} height={20} />}
          <span className="line-clamp-1 w-full text-ellipsis">{title}</span>
        </button>
      </Link>
    </li>
  );
};
