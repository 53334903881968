'use client';

import { ChevronDownIcon } from '@heroicons/react/24/outline';
import clsx from 'clsx';
import { useState } from 'react';

export const MobileHeaderNavSection = ({
  selected,
  isVisible,
  compact,
  title,
  children,
}: {
  selected?: boolean;
  isVisible: boolean;
  title: string;
  compact: boolean;
  children: React.ReactNode;
}) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      <li role="menuitem" className="list-none">
        <button
          aria-hidden={!isVisible}
          aria-expanded={isOpen}
          className={clsx('flex w-full flex-row items-center justify-between px-4 py-3', selected && 'bg-brandGreen-100')}
          onClick={() => setIsOpen(!isOpen)}
          tabIndex={!isVisible ? -1 : undefined}
        >
          <div className="flex w-full flex-row items-center justify-start gap-2">
            {selected && <div className="h-2.5 w-2.5 rounded-full bg-elty-green" />}
            <span>{title}</span>
          </div>
          <ChevronDownIcon aria-hidden className={clsx('h-5 w-5', isOpen && 'rotate-180')} />
        </button>
        <nav
          className={clsx(
            'left-0 top-0 z-10 h-full w-full  transition-all',
            isOpen ? 'max-h-96 opacity-100 duration-700' : 'max-h-0 overflow-hidden opacity-0 duration-300',
          )}
          aria-labelledby={`top-section-label-${'id'}`}
          aria-hidden={!isOpen}
          id={`mobile-menu-${'abc'}`}
        >
          <ul role="menu" className={clsx('flex flex-col gap-5', compact ? 'p-4' : 'p-8')}>
            {children}
          </ul>
        </nav>
      </li>
    </>
  );
};
